let mapContainer = $('#map');
function mapInit() {
    let contactsMap, contactsPlacemark;
    contactsMap = new ymaps.Map(mapContainer[0], {
        center: mapPoints[0].latlng,
        controls: [],//'fullscreenControl'
        zoom: 18,//от 0 (весь мир) до 19.
    },{

    });

    contactsMap.controls.add('zoomControl', {
        size: "small",
        // float: 'right'
        position: {
            right: '10px',
            bottom: '40px'
        }
    });
    contactsMap.controls.add('geolocationControl', {
        position: {
            right: '10px',
            bottom: '120px'
        }
    });
    // Сдвинем карту на 128 пикселей влево.
    let position = contactsMap.getGlobalPixelCenter();
    if ( window.innerWidth >= 641 ) {
        contactsMap.controls.add('typeSelector', {

        });
        contactsMap.setGlobalPixelCenter([position[0] - 128, position[1]]);
    }else{
        contactsMap.setGlobalPixelCenter([position[0], position[1] - 200]);
    }




    if (mapPoints.length) {
        for (let i=0; i<mapPoints.length; i++) {
            let point = mapPoints[i];

            contactsPlacemark = new ymaps.Placemark(point.latlng);
            contactsMap.geoObjects.add(contactsPlacemark);
        }
    }

    contactsMap.behaviors.disable('scrollZoom');
    if (isMobile.any()) {
        contactsMap.behaviors.disable('drag');
    }

}
$(function() {

    if (mapContainer.length) {
        setTimeout(function () {
            let elem = document.createElement('script');
            elem.type = 'text/javascript';
            elem.async = true;
            mapContainer[0].appendChild(elem);
            elem.src = 'https://api-maps.yandex.ru/2.1/?&lang=ru_RU&onload=mapInit';
        }, 2000);
    }

});