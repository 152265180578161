$(function() {

    let animSpeed = 900;
    let officeSl = new Swiper('.office-sl', {
        navigation: {
            nextEl: '.office .slider-nav__next',
            prevEl: '.office .slider-nav__prev',
        },
        speed: animSpeed
    });

});