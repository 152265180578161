$(function () {

    let animSpeed = 600;
    let navSlider = $('.projects-nav');
    let navSlides = navSlider.find('.projects-nav-i');
    let slider = new Swiper('.projects-slider', {
        slidesPerView: 1,
        autoHeight: true,
        speed: animSpeed,
        // loop: true,
        lazy: {
            loadPrevNext: true,
        },
        navigation: {
            nextEl: '.projects-slider .slider-nav__next',
            prevEl: '.projects-slider .slider-nav__prev',
        },
        on: {
            slideChange: function () {
                navSlides.removeClass('is-active');
                navSlides.eq(slider.realIndex).addClass('is-active');
            }
        }
    });

    navSlider.on('click', '.projects-nav-i:not(.is-active)', function (e) {
        // let indexSl = [...navSlides].indexOf(this),
        //     indexActiveSl = [...navSlides].indexOf(document.querySelector('.projects-nav-i.is-active'));
        let indexSl = $(this).index(),
            indexActiveSl = $('.projects-nav-i.is-active').index();
        navSlides.removeClass('is-active');
        $(this).addClass('is-active');
        if (slider) {
            if ( indexSl+1 === indexActiveSl || indexSl-1 === indexActiveSl ) {
                slider.slideTo(indexSl, animSpeed, false);
            }else{
                if (indexSl<indexActiveSl) {
                    slider.slideTo(indexSl+1, false, false);
                    slider.slideTo(indexSl, animSpeed, false);
                }else{
                    slider.slideTo(indexSl-1, false, false);
                    slider.slideTo(indexSl, animSpeed, false);
                }
            }
        }
    });

});