$(function() {

    let quest = $('.quiz__question'),
        progress = $('.quiz progress'),
        questions = +quest.data('questions'),
        step = +(100/questions).toFixed(),
        mes = $('.quiz__mes');

    $('.quiz__form input[type=radio]').on('change', function() {
        stepFunc($(this).closest('.quiz__item'));
    });
    $('.quiz__skip').on('click', function(e) {
        e.preventDefault();
        stepFunc($(this).closest('.quiz__item'));
    });

    function stepFunc(item) {
        item.hide().next().fadeIn();
        quest.find('span.is-active').removeClass('is-active').next().addClass('is-active');
        let progressVal = +progress.val()+step;
        if ( progressVal > 100 ) {
            progressVal = 100;
        }
        mes.find('span.is-active').removeClass('is-active').next().addClass('is-active');
        if ( mes.find('span.is-active').hasClass('hide') ) {
            mes.addClass('quiz__mes--hide');
        }else{
            mes.removeClass('quiz__mes--hide');
        }
        progress.val( progressVal );
    }

});