$(function () {
    const imageObserver = new IntersectionObserver((entries, imgObserver) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const lazyImage = entry.target;
                let src = lazyImage.dataset.lazySrc;
                let srcSet = lazyImage.dataset.lazySrcset;
                if ( lazyImage.nodeName.toLowerCase() === 'img' ) {
                    lazyImage.src = src;
                    if (srcSet) lazyImage.srcset = srcSet;
                }else{
                    lazyImage.style.backgroundImage = `url(${src})`;
                }
                imgObserver.unobserve(lazyImage);
            }
        })
    }, {
        rootMargin: '100px 0px 100px 0px'
    });
    imageObserver.POLL_INTERVAL = 100;
    const imgLazy = document.querySelectorAll('.img-lazy');
    for (let i = 0; i < imgLazy.length; i++) {
        imageObserver.observe(imgLazy[i]);
    }


    $('.input-tel').inputmask({
        mask: '+7 ( 999 ) 999-99-99',
        // placeholder: '+7 ( ___ ) ___-__-___',
        // showMaskOnHover: true,
        // showMaskOnFocus: true,
        clearMaskOnLostFocus: false,
    });
});

isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};
function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function getScrollbarWidth() {
    var outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.msOverflowStyle = "scrollbar";

    document.body.appendChild(outer);

    var widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = "scroll";

    // add innerdiv
    var inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);

    var widthWithScroll = inner.offsetWidth;

    // remove divs
    outer.parentNode.removeChild(outer);
    return widthNoScroll - widthWithScroll;
}

// проверка на боковой скролл
function hasScrollbar() {
    return $(document).height() > $(window).height();
}

function number_format(number, decimals, dec_point, thousands_sep) {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + (Math.round(n * k) / k)
                .toFixed(prec);
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
        .split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '')
        .length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1)
            .join('0');
    }
    return s.join(dec);
}

var mac = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i) ? true : false;

function modalOpenMac() {
    // if ( mac ) {
    //     if ($(document).height() > $(window).height() && !$("html").hasClass("apple-fix")) {
    //         var scrollTop = ($('html').scrollTop()) ? $('html').scrollTop() : $('body').scrollTop(); // Works for Chrome, Firefox, IE...
    //         $('html').css('top',-scrollTop);
    //         document.documentElement.classList.add("apple-fix");
    //     }
    // }
}

function modalCloseMac() {
    // if (mac) {
    //     var scrollTop = parseInt($('html').css('top'));
    //     document.documentElement.classList.remove("apple-fix");
    //     $('html,body').scrollTop(-scrollTop);
    // }
}