$(function () {

    let form = $('.hero-form');
    let input = form.find('.input-val');
    let label = form.find('.field-group__label');
    let maskOPtions ={
        mask: '+7 ( 999 ) 999-99-99',
        // placeholder: '+7 ( ___ ) ___-__-___',
        // showMaskOnHover: true,
        // showMaskOnFocus: true,
        clearMaskOnLostFocus: false,
    };

    input.inputmask(maskOPtions);

    form.on('change', '.default-radio input', function (e) {
        let pb = $(this).closest('.default-radio');
        let labelText = pb.data('label-input');
        let svg = pb.find('.default-radio__text svg').clone();
        // input.val('');
        //
        // if (pb.hasClass('ph')) {
        //     input.inputmask(maskOPtions);
        //     input.removeClass('border');
        // } else {
        //     input.inputmask('remove');
        //     input.addClass('border').attr('placeholder', '');
        // }

        label.html(labelText);
        form.find('.input-wrapper .ic').html(svg);
    });
});